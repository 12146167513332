import React from 'react'
import { AiOutlineTeam } from 'react-icons/ai'
import { BsDatabaseDown, BsFillFileTextFill, BsPeople } from 'react-icons/bs'
import { FaDatabase, FaFileContract, FaUsers } from 'react-icons/fa'
import { FiSettings } from 'react-icons/fi'
import { HiUsers } from 'react-icons/hi2'
import { MdAccountBalance, MdHealthAndSafety, MdOutlineDashboardCustomize } from 'react-icons/md'
import { RiUserSettingsLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import Settingbar from '../container/Settingbar'

const Settings = () => {
  return (
    <Settingbar>
<div className="p-8 relative">
      <div className="space-y-8">
        {/* Team Section */}
        <div>
          <h2 className="text-xl font-bold mb-4">Team</h2>
          <div className="grid grid-cols-3 gap-6">
            <Link to="/settings/users" className="flex items-start space-x-3 ">
              <div className='flex gap-x-2 py-1 px-2 rounded-md hover:bg-gray-300'>
              <FaUsers className="text-2xl text-blue-500"  size={24}/>
              <div>
                <h3 className="font-medium">Users</h3>
                <p className="text-sm text-gray-500">Create and Manage Users. Define Roles at User Level</p>
                </div>
              </div>
            </Link>
            <div className="flex items-start space-x-3">
              <div className='flex gap-x-2 hover:bg-gray-300 py-1 px-2 rounded-md'>
              <RiUserSettingsLine className="text-2xl text-blue-500"  size={24}/>
              <div>
                <h3 className="font-medium">Roles and Permissions</h3>
                <p className="text-sm text-gray-500">Provide and restrict fine-grained levels of access and privileges for users</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Workflows Section */}
        <div>
          <h2 className="text-xl font-bold mb-4">Workflows</h2>
          <div className="grid grid-cols-3 gap-6">
            <div className="flex items-start space-x-3">
              <Link to="/settings/accountDetails">
              <div className='flex gap-x-2 hover:bg-gray-300 py-1 px-2 rounded-md'>
              <BsFillFileTextFill className="text-2xl text-blue-500"  size={24}/>
                <div>
                <h3 className="font-medium">Account Details</h3>
                <p className="text-sm text-gray-500">Customize your account fields. Edit or update the predefined fields</p>
                </div>
              </div>
              </Link>
            </div>
            <div className="flex items-start space-x-3">
            <Link to="/settings/accountTeams">
              <div className='flex gap-x-2 hover:bg-gray-300 py-1 px-2 rounded-md h-[70px]'>
              <BsPeople className="text-2xl text-blue-500"  size={24}/>
              <div>
                <h3 className="font-medium">Account Team</h3>
                <p className="text-sm text-gray-500">Create and Customize your Account Team</p>
              </div>
              </div>
              </Link>
            </div>
            <div className="flex items-start space-x-3">
            <Link to="/settings/health">
            <div className='flex gap-x-2 hover:bg-gray-300 py-1 px-2 rounded-md h-[70px]'>
              <MdHealthAndSafety className="text-2xl text-blue-500"  size={24}/>
              <div>
                <h3 className="font-medium">Health & Engagement</h3>
                <p className="text-sm text-gray-500">Create and Customize the fields at your Account Health</p>
              </div>
              </div>
              </Link>
            </div>
            <div className="flex items-start space-x-3">
            <Link to="/settings/product">
            <div className='flex gap-x-2 hover:bg-gray-300 py-1 px-2 rounded-md h-[70px]'>
              <FaFileContract className="text-2xl text-blue-500"  size={24}/>
              <div>
                <h3 className="font-medium">Contract and Product</h3>
                <p className="text-sm text-gray-500">Customize your account fields. Edit or update the predefined fields</p>
              </div>
              </div>
              </Link>
            </div>
            <div className="flex items-start space-x-3">
            <div className='flex gap-x-2 hover:bg-gray-300 py-1 px-2 rounded-md h-[70px]'>

              <FiSettings className="text-2xl text-blue-500"  size={24}/>
              <div>
                <h3 className="font-medium">Success Plan</h3>
                <p className="text-sm text-gray-500">Customize your account fields. Edit or update the predefined fields</p>
              </div>
              </div>
            </div>
            <div className="flex items-start space-x-3">
            <div className='flex gap-x-2 hover:bg-gray-300 py-1 px-2 rounded-md h-[70px]'>

              <FiSettings className="text-2xl text-blue-500"  size={24}/>
              <div>
                <h3 className="font-medium">CRM</h3>
                <p className="text-sm text-gray-500">Customize your account fields. Edit or update the predefined fields</p>
              </div>
              </div>
            </div>
          </div>
        </div>

        {/* Data Import Section */}
        <div>
          <h2 className="text-xl font-bold mb-4">Data Import</h2>
          <div className="grid grid-cols-3 gap-6">
            <div className="flex items-start space-x-3">
            <Link to="/settings/AccountDataUpload">
            <div className='flex gap-x-2 hover:bg-gray-300 py-1 px-2 rounded-md h-[70px]'>
              <FaFileContract className="text-2xl text-blue-500"  size={24}/>
              <div>
                <h3 className="font-medium">Account Data</h3>
                <p className="text-sm text-gray-500">Upload your Accounts, Details and assign the Team</p>
              </div>
              </div>
              </Link>
            </div>
            <div className="flex items-start space-x-3">
            <div className='flex gap-x-2 hover:bg-gray-300 py-1 px-2 rounded-md h-[70px]'>

              <FaDatabase className="text-2xl text-blue-500"  size={24}/>
              <div>
                <h3 className="font-medium">Health Data</h3>
                <p className="text-sm text-gray-500">Customize your account fields. Edit or update the predefined fields</p>
              </div>
              </div>
            </div>
          </div>
        </div>

        {/* Account Section */}
        <div>
          <h2 className="text-xl font-bold mb-4">Account</h2>
          <div className="grid grid-cols-1 gap-6">
            <div className="flex items-start space-x-3">
              {/* <AiOutlineTeam className="text-2xl text-blue-500" />
              <div>
                <h3 className="font-medium">Account Management</h3>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    
    </div>



    </Settingbar>
  )
}

export default Settings





// import React from 'react';
// import { FaUsers, FaUpload, FaLock, FaMoneyBillAlt } from 'react-icons/fa';
// import { MdAccountBox, MdHealthAndSafety, MdOutlinePlan, MdOutlineExpansion, MdOutlineRequestPage } from 'react-icons/md';
// import Sidebar from '../container/Sidebar';
// import { IoRocketOutline } from 'react-icons/io5';
// import { AiOutlineDollar } from 'react-icons/ai';

// const Settings = () => {
//   const settingsOptions = [
//     { icon: <FaUsers />, title: "Users and Team", description: "Create and manage users, assign them roles" },
//     { icon: <MdAccountBox />, title: "Account Fields", description: "Manage Fields to be shown in the Account Level. Edit, Add or Remove the Fields" },
//     { icon: <FaUpload />, title: "Data Upload", description: "Upload the Account and Health data through a CSV or Sync you API’s" },
//     { icon: <FaLock />, title: "Permissions", description: "Define the Rules of users. Update what they can see and edit." },
//     { icon: <FaMoneyBillAlt />, title: "Plans and Billing", description: "Manage your Triumph Plans and user counts" },
//     { icon: <MdHealthAndSafety />, title: "Customer Health Fields", description: "Manage Fields to be shown in the Health. Edit, Add or Remove the Fields" },
//     { icon: <IoRocketOutline  />, title: "Success Plan Fields", description: "Manage Fields to be shown in Success Plan. Edit, Add or Remove the Fields" },
//     { icon: <AiOutlineDollar />, title: "Expansion Fields", description: "Manage Fields to be shown in Expansion. Edit, Add or Remove the Fields" },
//     { icon: <MdOutlineRequestPage />, title: "Requests / To Do Fields", description: "Manage Fields to be shown in Requests. Edit, Add or Remove the Fields" },
//   ];

//   return (
//     <Sidebar >
//     <div className="p-3 bg-gray-50 border border-gray-200 rounded-md border-opacity-50 shadow">
//       <h2 className="text-2xl font-semibold mb-4">Settings</h2>
//       <div className="bg-white shadow rounded-md cursor-pointer">
//         {settingsOptions.map((option, index) => (
//           <div
//             key={index}
//             className="flex items-center p-4 border-b last:border-none hover:bg-gray-100"
//           >
//             <div className="text-gray-500 text-xl mr-4 ">{option.icon}</div>
//             <div>
//               <h3 className="font-medium text-gray-900 text-sm">{option.title}</h3>
//               <p className="text-gray-500 text-xs">{option.description}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//     </Sidebar>
//   );
// };

// export default Settings;
