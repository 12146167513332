import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Signup = () => {
  // const [organisationUrl, setOrganisationUrl] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmailId, setUserEmailId] = useState("");
  const [organisationName, setOrganisationName] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const token =localStorage.getItem('authToken');
  console.log(token);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch("https://be.triumphiq.com/organisation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userName, userEmailId, organisationName }),
    });
    // console.log({ userName, userEmailId, organisationName });

    const data = await response.json();
    if (response.ok) {
      // console.log("SignUp successful", data);
      // console.log(data.organisationUrl);
      window.location.href = `${data.organisationUrl}/onboard?token=${data.token}`;
      // localStorage.setItem('authToken', data.token);
      // localStorage.setItem('adminName', data.adminName);
      // window.location.href =(`${data.organisationUrl}/onboard`);
    } else {
      console.error("SignUp failed", data);
    }
  };
 
  return (
    <div className="w-full h-screen p-20 flex gap-4 bg-gray-50">
      <div className=" h-[600px] w-[50%] mt-7 mx-5">
        <div className="">
          <h2 className="text-[24px] font-bold">Get started Now</h2>
          <p className="text-xs">
            Enter your credentials to access your account
          </p>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="mt-4">
              <h3 className="text-sm font-semibold">Name</h3>
              <input
                id="name"
                name="name"
                type="text"
                required
                placeholder="Aditya Yadav"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                className="w-full max-w-[500px] placeholder-gray-400 px-3 py-3 mt-1 border border-gray-300 rounded-lg border-opacity-70 bg-white shadow-sm text-sm"
              />
            </div>
            <div>
              <p className="text-sm font-semibold">Email address</p>
              <input
                id="email"
                name="email"
                type="email"
                required
                placeholder="email"
                value={userEmailId}
                onChange={(e) => setUserEmailId(e.target.value)}
                className="w-full max-w-[500px] placeholder-gray-400 px-3 py-3 mt-1 border border-gray-300 rounded-lg border-opacity-70 bg-white shadow-sm text-sm"
              />
            </div>

            <div>
              <p className="text-sm font-semibold">Organisation Name</p>
              <input
                id="text"
                name="text"
                type="text"
                required
                placeholder="url here"
                value={organisationName}
                onChange={(e) => setOrganisationName(e.target.value)}
                className="w-full max-w-[500px] placeholder-gray-400 px-3 py-3 mt-1 border border-gray-300 rounded-lg border-opacity-70 bg-white shadow-sm text-sm"
              />
            </div>
            <div className="flex gap-1">
              <input
                type="checkbox"
                className="cursor-pointer"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <p className="text-xs">I agree to the terms & privacy</p>
            </div>
            <button
              type="submit"
              disabled={!isChecked} // Button is disabled if checkbox is not checked
              className={`w-[500px] h-12 rounded-full flex items-center justify-center ${
                isChecked
                  ? "bg-blue-700 text-white"
                  : "bg-gray-400 text-gray-200 cursor-not-allowed"
              }`}
            >
              Sign up
            </button>
          </form>
          <p className="text-xs mt-4">
            Have an account?{" "}
            <Link to="/login" className="text-blue-600 underline">
              Sign in
            </Link>
          </p>
        </div>
      </div>
      <div className="h-[550px] my-auto w-[50%] bg-blue-600 rounded-2xl">
        {/* content  */}
        <div className="p-8 px-12 text-white">
          <h2 className="text-[24px] font-bold w-[350px] ">
            The simplest way to manage your Customers
          </h2>
          <p className="text-sm text-gray-200 w-[260px] mt-3">
            Know your customer better. Reduce Churn. Grow your Cusomters
          </p>
          {/* <div className='mt-10'>
       <Carousel/>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Signup;
