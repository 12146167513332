import React, { useEffect, useRef, useState } from 'react';
import { FaSearch, FaKey, FaTrashAlt, FaFilter } from 'react-icons/fa';
import { BsPersonPlus } from 'react-icons/bs';
import Sidebar from '../../container/Sidebar';
import Settingbar from '../../container/Settingbar';
import { jwtDecode } from "jwt-decode";
import { IoArrowBack, IoCloseCircleSharp } from 'react-icons/io5';
import { MdArrowBackIosNew, MdCloudUpload } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { CiFilter } from 'react-icons/ci';
import axios from 'axios';

const UsersAndTeam = () => {
   const [addUser, setAddUser]= useState(false)
   const [addcsv, setAddcsv]= useState(false)
  const [userData, setUserData] = useState(null);
  const [allUsers, setAllUsers]= useState(null)
  const [name, setName]= useState('')
  const [email, setEmail]= useState('')
  const [role, setRole]= useState('')
  const [organisationCname, setOrganisationCname]= useState(window.location.hostname.split('.')[0]);

  const handleOnclick = ()=>{
    setAddUser(!addUser);
  }
  const handlecsvclick = ()=>{
    setAddcsv(!addcsv);
  }

  const getAllUsers = async () => {
    const token = localStorage.getItem('authToken')
    try {
      const response = await fetch(`https://be.triumphiq.com/users?organisationCname=${window.location.hostname.split('.')[0]}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setAllUsers(result);
      console.log('got it', allUsers);
    } catch (error) {
     console.error('error', error)
    } 
  };

 // get all users
  useEffect(() => {
    getAllUsers();
  }, []);


  // add user
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://be.triumphiq.com/users",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
          body: JSON.stringify({
            email,
            name,
            role,
            organisationCname,
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        alert("Success");
      setAddUser(!addUser);
    getAllUsers();
       console.log('Sucessfully added', data);
      } else {
        console.error("SignUp failed", data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fileInputRef = useRef(null);


//upload file
  const handleFileChange = async () => {
    const file = fileInputRef.current.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      const token = localStorage.getItem('authToken');

      try {
        const response = await axios.post(`https://be.triumphiq.com/users/bulk?file=${file}`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log('File uploaded successfully:', response.data);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    } else {
      console.log('No file selected');
    }
  };

  return (
    <Settingbar>
    <div className="p-3 bg-gray-50 relative">
      <div className="flex items-center justify-between mb-4">
        <div className='flex gap-3 items-center'>
          <Link to="/settings">
        <MdArrowBackIosNew  size={20} />
        </Link>
        <h2 className="text-xl font-semibold">Users</h2>
        </div>
        </div>
        <div className='flex gap-4 items-center justify-between'>
          <div className='flex gap-4 items-center'>
          <input type="text" className='border-2 bg-gray-200 h-8 w-64 border-gray-300 rounded-lg px-1'placeholder='search'/>
          <p className='text-sm'>{`Total Users: ${allUsers?.length}`}</p>
          <CiFilter size={22} />
          </div>
          <div className='flex gap-4 items-center mb-3'>
        <button className="flex items-center px-4 py-2 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={handleOnclick}>
          <BsPersonPlus className="mr-2" /> Add User
        </button>
        <button className="flex items-center px-4 py-2 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={handlecsvclick}>
          <BsPersonPlus className="mr-2" /> Upload in Bulk
        </button>
        </div>
      </div>
      <div className="bg-white shadow rounded-md overflow-x-auto">
        <table className="min-w-full table-auto">
          <thead className="bg-gray-100 text-sm">
            <tr>
              <th className="p-4 text-left">User Name</th>
              <th className="p-4 text-left">Email</th>
              <th className="p-4 text-left">Role</th>
              <th className="p-4 text-left">Status</th>
              <th className="p-4 text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
           {allUsers?.map((data, index)=>{
            return (
              <tr className="border-b hover:bg-gray-50 text-sm" key={index}>
               
                <td className="p-4 flex items-center">
                {data?.username}
                </td>
                <td className="p-4">
                  <span className=" py-1  rounded-full" >
                  {data?.username}
                  </span>
                </td>
                <td className='p-4'> {data?.authorities[0]?.authority}</td>
                <td className="p-4 text-gray-500 text-sm">{localStorage.getItem('authToken')? <p>active</p>: <p>Inactive</p>}</td>
                <td className="p-4 flex justify-end items-center space-x-4 text-gray-500 gap-1 text-xs cursor-pointer">
                 
                  <FaTrashAlt className="cursor-pointer hover:text-gray-700" title="Delete" /> Remove
                </td>
              </tr> 
          )
         })}
          </tbody>
        </table>
      </div>
      <div className={`absolute ${addUser?'block':'hidden'} top-72 inset-0 flex items-center justify-center`}>
      <div class="border-2 border-gray-300 bg-white rounded-md p-4 h-[220px] w-[700px]">
      <IoCloseCircleSharp size={24} onClick={handleOnclick} className='cursor-pointer float-end' />
      <h2 className='text-center font-semibold pt-4'>Add User</h2>
      <form action="" onSubmit={handleOnSubmit}>
      <div className='flex flex-wrap justify-around p-2 pt-6 text-sm'>
        <div className='w-24 flex flex-col justify-center items-center'>
          <h2>Name</h2>
          <input type="text" className='border-2 w-42 rounded-md h-8  px-1 border-gray-300' placeholder='Enter name' value={name} onChange={(e)=>setName(e.target.value)}/>
        </div>
        <div className='w-24 flex flex-col justify-center items-center'>
          <h2>Email Address</h2>
          <input  className='border-2 rounded-md h-8 w-56 px-1 border-gray-300' placeholder='Email' id='email' type="email" value={email}  onChange={(e) => setEmail(e.target.value)}/>
        </div>
        <div className='w-24 flex flex-col justify-center items-center'>
          <h2>Role</h2>
          <select name="role" id="role" className='w-28 border-2 border-gray-300 rounded-md' value={role}  onChange={(e) => setRole(e.target.value)}>
          <option value="" disabled>Select </option>
            <option value="CSM">CSM</option>
            <option value="admin">Admin</option>
          </select>
          {/* <input type="text" className='border'value={role} onChange={(e)=>setRole(e.target.value)}/> */}
        </div>
      </div>
      <div className='flex  justify-end px-10 pt-5'>
      <button className='flex bg-blue-500 px-8 text-white rounded-md py-1' type='submit'>save</button>
      </div>
      </form>
    </div>
</div>
      <div className={`absolute ${addcsv?'block':'hidden'} top-72 inset-0 flex items-center justify-center`}>
      <div class="border-2 border-gray-300 bg-white rounded-md p-4 h-[250px] w-[500px]">
        <div className='flex justify-end px-2'>
      <IoCloseCircleSharp size={24} onClick={handlecsvclick} className='cursor-pointer' />
      </div>
      <h2 className='text-center font-semibold'>Download the template</h2>
      <div className='flex flex-col items-center'>
      <MdCloudUpload size={100} color="blue" />
      {/* <button className='py-2 px-4 bg-blue-500 text-white' onClick={handleButtonClick}>Browse files</button> */}
      <input
        type="file"
        ref={fileInputRef}
        // style={{ display: 'none' }}
        onChange={handleFileChange}
        accept=".csv"
        multiple 
      />
       <button onClick={() => fileInputRef.current.click()}>Upload Users</button>
      </div>
    </div>
</div>
    </div>
    </Settingbar>
  );
};

export default UsersAndTeam;
