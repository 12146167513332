import React, { Children, useEffect, useState } from "react";
import { BsFillSunFill, BsMoonStarsFill } from "react-icons/bs";
import {
  FaAddressBook,
  FaBell,
  FaPlus,
  FaRegUser,
  FaShieldAlt,
} from "react-icons/fa";
import Overview from "../components/singlePageAccount/Overview";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  IoCloseSharp,
  IoHelpCircleOutline,
  IoMoon,
  IoSettingsOutline,
} from "react-icons/io5";
import { HiMiniUsers } from "react-icons/hi2";
import { RiUserAddFill } from "react-icons/ri";
import { PiPlugsConnectedFill } from "react-icons/pi";
import { MdContactPage, MdSpaceDashboard } from "react-icons/md";
import { FaCodePullRequest } from "react-icons/fa6";
import { HiDocumentReport } from "react-icons/hi";
import { CiBag1 } from "react-icons/ci";
import Account from "../pages/Account";
import Dashboard from "../pages/Dashboard";
import Project from "../components/singlePageAccount/Project";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

const Sidebar = ({children}) => {
  const [open, setOpen] = useState(false);
  const [greeting, setGreeting] = useState('');

  const handleOnlclick = () => {
    setOpen(!open);
    console.log(open);
  };


  useEffect(() => {
    const calculateGreeting = () => {
     
      const now = new Date();

      const istOffset = 5.5 * 60 * 60 * 1000; 
      const istTime = new Date(now.getTime() + istOffset);

     
      const hours = istTime.getUTCHours();
   
      if (hours < 12) {
        setGreeting('Good Morning');
      } else if (hours >= 12 && hours < 17) {
        setGreeting('Good Afternoon');
      } else {
        setGreeting('Good Evening');
      }
    };

    calculateGreeting();
   
    const interval = setInterval(calculateGreeting, 60000);
    return () => clearInterval(interval);
  }, []);

  
  return (
    <div className="w-full h-full relative">
      <div className="h-screen w-full ">
        {/* sidebar  */}

        <div className=" bg-gray-300 hidden lg:block w-full ">
          <div className="fixed top-0 left-0 h-screen p-3 z-50 w-64 bg-gray-300">
            <div className="flex gap-2">
              <FaAddressBook size={22} color="scarlet" />
              <h2 className="text-sm">Company Name</h2>
            </div>

            <div className="mt-8 text-sm">
              <NavLink to="/"
          className={({ isActive }) =>
            `flex items-center gap-2 h-6 mb-4 ${isActive ? 'bg-black text-white rounded-md px-2 ' : ''}`
          }>
                <MdSpaceDashboard size={17} />
                DashBoard
              </NavLink>
              {/* <div className="h-[0.5px] xl:w-full w-[150px]  bg-gray-400 mt-4"></div> */}
              <h3 className="text-[#9095A1FF] mt-2">Account Management</h3>
              <div className="mt-3">
                <ul className="space-y-3 xl:w-[170px] px-2">
                <NavLink
          to="/accounts"
          className={({ isActive }) =>
            `flex items-center gap-2 h-6   ${isActive ? 'bg-black text-white px-2 rounded-md ' : ''}`
          }
        >
          <HiMiniUsers size={15} />
          Accounts
        </NavLink>
        <NavLink
          to="/success"
          className={({ isActive }) =>
            `flex items-center gap-2 h-6    ${isActive ? 'bg-black text-white rounded-md px-2' : ''}`
          }
        >
          <RiUserAddFill size={15} />
          Success Plan
        </NavLink> 
        <NavLink
          to="/request"
          className={({ isActive }) =>
            `flex items-center gap-2 h-6 ${isActive ? 'bg-black text-white rounded-md px-2' : ''}`
          }
        >
          <FaCodePullRequest size={15} />
          Request
        </NavLink>
        <NavLink
          to="/expansion"
          className={({ isActive }) =>
            `flex items-center gap-2 h-6 ${isActive ? 'bg-black text-white rounded-md px-2' : ''}`
          }
        >
          <CiBag1 size={15} />
          Expansion Hub
        </NavLink>
        <NavLink
          to="/reports"
          className={({ isActive }) =>
            `flex items-center gap-2 h-6 ${isActive ? 'bg-black text-white rounded-md px-2' : ''}`
          }
        >
          <HiDocumentReport size={15} />
          Reports
        </NavLink>
        <NavLink
          to="/alerts"
          className={({ isActive }) =>
            `flex items-center gap-2 h-6 ${isActive ? 'bg-black text-white rounded-md px-2' : ''}`
          }
        >
          <FaBell size={15} />
          Alerts
        </NavLink>
        <NavLink
          to="/integrations"
          className={({ isActive }) =>
            `flex items-center gap-2 h-6 ${isActive ? 'bg-black text-white rounded-md px-2' : ''}`
          }
        >
          <PiPlugsConnectedFill size={15} />
          Integrations
        </NavLink>
        <NavLink
          to="/contacts"
          className={({ isActive }) =>
            `flex items-center gap-2 h-6 ${isActive ? 'bg-black text-white rounded-md px-2' : ''}`
          }
        >
          <MdContactPage size={15} />
          Contacts
        </NavLink>
      </ul>
              </div>

              {/* <div className="h-[0.5px] bg-gray-400 xl:w-full w-[150px]  mt-6 mb-3"></div> */}
              <h3 className="text-[#9095A1FF] mt-5">Support</h3>
              <div className="mt-3">
                <ul className="space-y-3 xl:w-[170px] px-2">
                <NavLink
          to="/settings"
          className={({ isActive }) =>
            `flex items-center gap-2 h-6 ${isActive ? 'bg-black text-white rounded-md px-2' : ''}`
          }
        >
                    <IoSettingsOutline size={15} />
                    Settings
                  </NavLink>
                  <NavLink
          to="/help"
          className={({ isActive }) =>
            `flex items-center gap-2 h-6 ${isActive ? 'bg-black text-white rounded-md px-2' : ''}`
          }
        >
                    <IoHelpCircleOutline size={15} />
                    Help Centre
                  </NavLink>
                  <NavLink
          to="/security"
          className={({ isActive }) =>
            `flex items-center gap-2 h-6 ${isActive ? 'bg-black text-white rounded-md px-2' : ''}`
          }
        >
                    <FaShieldAlt size={15} />
                    Security
                  </NavLink>
                </ul>
              </div>
            </div>

          </div>
        </div>
        <div className=" ml-64">
          {/* searchBar  */}
          <div className=" top-0 ml-64 w-full h-10 bg-blue-300  flex items-center inset-0 px-2 justify-between fixed z-30 ">
            <div className="flex  space-x-2 items-center">
              {greeting===("Good Morning" || "Good Afternoon")? <BsFillSunFill size={20} color="orange" /> : <BsMoonStarsFill  size={20} color="gray" />}
              <p>{greeting}</p>
              <p>
                <strong>Aditya</strong>
              </p>
            </div>

            <div className="flex gap-2  z-0">
              <FaRegUser size={20} />
              <GiHamburgerMenu
                size={20}
                className="block lg:hidden cursor-pointer "
                onClick={handleOnlclick}
              />
              <div
                className={`${
                  open ? "block" : "hidden"
                } sm:w-[40%] w-[60%] lg:hidden z-50 fixed h-full  bg-slate-300 top-0 right-0`}
              >
                <IoCloseSharp
                  className="cursor-pointer float-right m-2 "
                  size={20}
                  onClick={handleOnlclick}
                />

                <div className="fixed m-5 w-[200px]  ">
                  <div className="flex gap-2">
                    <FaAddressBook size={22} color="scarlet" />
                    <h2 className="text-sm">Company Name</h2>
                  </div>
                  <div className="mt-8 text-sm">
                    <h2 className="text-[#171A1FFF] flex items-center gap-2">
                      <MdSpaceDashboard size={17} />
                      DashBoard
                    </h2>
                    <div className="h-[0.5px] w-full  bg-gray-400 mt-4"></div>
                    <h3 className="text-[#9095A1FF] mt-2">
                      Account Management
                    </h3>
                    <div className="mt-4">
                      <ul className="space-y-3">
                        <Link to="/accounts" className="flex items-center gap-2">
                          <HiMiniUsers size={15} />
                          Accounts
                        </Link>
                        <li className="flex items-center gap-2">
                          {" "}
                          <RiUserAddFill size={15} />
                          Success Plan
                        </li>
                        <li className="flex items-center gap-2">
                          <FaCodePullRequest size={15} />
                          Request
                        </li>
                        <li className="flex items-center gap-2">
                          <CiBag1 size={15} />
                          Expansion Hub
                        </li>
                        <li className="flex items-center gap-2">
                          <HiDocumentReport size={15} />
                          Reports
                        </li>
                        <li className="flex items-center gap-2">
                          <FaBell size={15} />
                          Alerts
                        </li>
                        <li className="flex items-center gap-2">
                          <PiPlugsConnectedFill size={15} />
                          Integrations
                        </li>
                        <li className="flex items-center gap-2">
                          {" "}
                          <MdContactPage size={15} />
                          Contacts
                        </li>
                      </ul>
                    </div>

                    <div className="h-[0.5px] bg-gray-400 xl:w-full w-[150px]  mt-6 mb-3"></div>
                    <h3 className="text-[#9095A1FF] ">Support</h3>
                    <div className="mt-4">
                      <ul className="space-y-3">
                        <li className="flex items-center gap-2">
                          <IoSettingsOutline size={15} />
                          Settings
                        </li>
                        <li className="flex items-center gap-2">
                          {" "}
                          <IoHelpCircleOutline size={15} />
                          Help Centre
                        </li>
                        <li className="flex items-center gap-2">
                          {" "}
                          <FaShieldAlt size={15} />
                          Security
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="mt-14 md:mt-10 ">
         {children}
          </div>
        </div>
        <div className="md:hidden  fixed h-10 w-10 bottom-3 rounded-full bg-blue-500 left-3 justify-center flex items-center">
          <FaPlus size={20} color="white" />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
