import React, { useEffect, useState } from 'react'
import Sample from './container/Sidebar'
import Login from './auth/Login'
import Signup from './auth/Signup'
import { Navigate, Route,BrowserRouter as Router, Routes } from 'react-router-dom'
import Overview from './components/singlePageAccount/Overview'
import Dashboard from './pages/Dashboard'
import Users from "./components/settings/Users"
import Sidebar from './container/Sidebar'
import Account from './pages/Account'
import Success from './pages/Success'
import Request from './pages/Request'
import SingleAccount from './components/singlePageAccount/SingleAccount'
import Settings from './pages/Settings'
import Onboard from './components/onboarding/Onboard'
import AccountField from './components/settings/AccountTeams'
import Verify from './auth/Verify'
import AccountDetails from './components/settings/AccountDetails'
import AccountTeams from './components/settings/AccountTeams'
import Health from './components/settings/Health'
import Product from './components/settings/Product'
import UploadAccData from './components/settings/UploadAccData'

const App = () => {
  const [authToken, setAuthToken] = useState(localStorage.getItem("authToken"));

  useEffect(() => {
    // Update authToken in state when it changes in localStorage
    const handleStorageChange = () => {
      setAuthToken(localStorage.getItem("authToken"));
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);


  return (
    <>
      <Router>
       {authToken === null? (
        <Routes>
        <Route exact path="/signup" element={<Signup/>} />
        <Route exact path="/login" element={<Login/>} />
        <Route exact path="/verify-account" element={<Verify/>} />  
        <Route  exact path="*" element={<Navigate to="/signup"/>} />
        <Route exact path="/onboard" element={<Onboard />} />
        </Routes>
       ):(
        <Routes>
        <Route exact path="/login" element={<Login/>} />
        <Route exact path="/accounts/:id" element={<SingleAccount/>} />
        <Route exact path="/accounts" element={<Account/>} />
        <Route exact path="/success" element={<Success/>} />
        <Route exact path="/request" element={<Request/>} />
        <Route exact path="/settings" element={<Settings/>} />
        <Route exact path="/settings/users" element={<Users/>} />
        <Route exact path="/onboard" element={<Onboard/>} />
        <Route exact path="/settings/accountTeams" element={<AccountTeams/>} />
        <Route exact path="/settings/accountDetails" element={<AccountDetails/>} />
        <Route exact path="/settings/AccountDataUpload" element={<UploadAccData/>} />
        <Route exact path="/settings/health" element={<Health/>} />
        <Route exact path="/settings/product" element={<Product/>} />
        <Route exact path="/" element={<Dashboard/>} />  
        {/* <Route  exact path="*" element={<Navigate to="/login"/>} /> */}
        </Routes>
       )
      }
      </Router>
      {/* <Login /> */}
    </>
  )
}

export default App
