import React, { useEffect, useState } from "react";
import Settingbar from "../../container/Settingbar";
import { Link } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import { IoCloseCircleSharp } from "react-icons/io5";

const AccountTeams = () => {
  const [data, setData] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const [addField, setAddField] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [fieldType, setFieldType] = useState("");
  const [editDropdown, setEditDropdown] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false);
  const [possibleValues, setPossibleValues] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [fieldToDelete, setFieldToDelete] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editField, setEditField] = useState({
    fieldName: "",
    fieldType: "",
    possibleValues: null,
    index: null,
  });
  const [visibilityFields, setVisibilityFields] = useState(null);
  const [visibilityChanged, setVisibilityChanged] = useState(false);

  /* 1. Api calls */
  // get all acc details
  const fetchData = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await fetch(
        `https://be.triumphiq.com/fields?organisationCname=${
          window.location.hostname.split(".")[0]
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("successfully fetched data");
        setData(data);
        console.log("object", data);
      }
    } catch (error) {
      console.error("ERROR", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  //add field api
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `https://be.triumphiq.com/fields?organisationCname=${
          window.location.hostname.split(".")[0]
        }`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          body: JSON.stringify({
            ...data,
            accountFields: [
              ...data.accountFields,
              {
                fieldName,
                fieldType,
                visibility: true,
                isDefaultField: false,
                possibleValues,
              },
            ],
          }),
        }
      );
      if (response.ok) {
        setAddField(!addField);
        alert("Success");
        setFieldName("");
        setFieldType("");
        setPossibleValues([]);
        fetchData();
      } else {
        console.error("Failed", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //delete field api
  const handleOnDelete = async (updatedArray) => {
    try {
      const response = await fetch(
        `https://be.triumphiq.com/fields?organisationCname=${
          window.location.hostname.split(".")[0]
        }`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          body: JSON.stringify(updatedArray),
        }
      );
      if (response.ok) {
        alert("Success");
        fetchData();
      } else {
        console.error("Failed", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // edit field
  const handleOnEdit = async (updatedFields) => {
    try {
      const response = await fetch(
        `https://be.triumphiq.com/fields?organisationCname=${
          window.location.hostname.split(".")[0]
        }`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          body: JSON.stringify(updatedFields),
        }
      );
      if (response.ok) {
        alert("Success");
        fetchData();
      } else {
        console.error("Failed", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // api for visibility
  const handleSaveChanges = async () => {
    try {
      const response = await fetch(
        `https://be.triumphiq.com/fields?organisationCname=${
          window.location.hostname.split(".")[0]
        }`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          body: JSON.stringify(visibilityFields),
        }
      );

      if (response.ok) {
        alert("Visibility changes saved successfully!");
        setVisibilityChanged(false);
        fetchData();
      } else {
        console.error("Failed to save changes", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // add field button handler
  const handleOnclick = () => {
    setAddField(!addField);
  };

  // dropdown input splitter
  const handleInputChange = (e) => {
    const values = e.target.value.split(",").map((v) => v.trim());
    setPossibleValues(values);
    console.log(values);
  };

  // delete filtering on confirmation delete
  const handleDelete = () => {
    // Filter out the field with the specific index
    if (fieldToDelete !== null) {
      const updatedFields = data.accountFields.filter(
        (field, index) => index !== fieldToDelete
      );

      const updatedArray = {
        ...data,
        accountFields: updatedFields,
      };

      handleOnDelete(updatedArray);
    }
    setShowPopup(false);
    setFieldToDelete(null);
  };

  // delete button on users
  const confirmDelete = (index) => {
    setFieldToDelete(index);
    setShowPopup(true);
  };

  //cancelling the delete
  const cancelDelete = () => {
    setShowPopup(false);
    setFieldToDelete(null);
  };

  // save on edit popup
  const handleSave = () => {
    const updatedFields = { ...data };
    updatedFields.accountFields[editField.index] = editField;
    setEditDropdown(false);
    setEditedData(updatedFields); // Update the state with the new fields array
    console.log("edited state", editedData);
    console.log("edited const", updatedFields);
    setIsEditOpen(false);
    handleOnEdit(updatedFields);
  };

  // edit button click
  const handleEditClick = (index) => {
    setEditField({ ...data?.accountFields[index], index });
    setIsEditOpen(true);
  };

  //visiblity function
  const handleToggleVisibility = (index) => {
    const updatedFields = { ...data };
    updatedFields.accountFields[index].visibility =
      !updatedFields.accountFields[index].visibility;
    setVisibilityFields(updatedFields);
    setVisibilityChanged(true);
  };

  return (
    <Settingbar>
      <div className="container mx-auto p-4 relative">
        <div className="flex gap-2 items-center mb-2">
          <Link to="/settings">
            <MdArrowBackIosNew size={20} />
          </Link>
          <h2 className="text-xl font-semibold ">Account Teams</h2>
        </div>
        <div className="bg-white  rounded-md shadow-md mb-4">
          <div className="h-12 w-full  flex justify-between items-center px-4 border-b-2">
            <h2 className="text-md">Account Teams</h2>
            <div className="flex items-center gap-4">
              {visibilityChanged && (
                <div className="">
                  <button
                    className="bg-blue-500 h-8 w-32 text-white rounded-md text-sm"
                    onClick={handleSaveChanges}
                  >
                    Save Changes
                  </button>
                </div>
              )}
              <button
                className="bg-blue-500 h-8 w-32 text-white rounded-md text-sm"
                onClick={handleOnclick}
              >
                Add Fields
              </button>
            </div>
          </div>
          <div className="p-4">
            <table className="min-w-full bg-white ">
              <thead>
                <tr className="font-semibold">
                  <th className="py-2 text-left text-sm text-gray-600 ">
                    Field Name
                  </th>
                  <th className="py-2 text-left text-sm text-gray-600 ">
                    Type
                  </th>
                  <th className="py-2 text-left text-sm text-gray-600 ">
                    Action
                  </th>
                  <th className="py-2 text-left text-sm text-gray-600 ">
                    Visibility
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.accountFields?.map((field, index) => (
                  <tr key={index}>
                    <td className="py-2 text-sm text-gray-700 ">
                      {field?.fieldName}
                    </td>
                    <td className="py-2 text-sm text-gray-700 ">
                      {field.fieldType === "ENUM" ? (
                        <p>Dropdown</p>
                      ) : (
                        field.fieldType
                      )}
                    </td>
                    <td className="py-2 text-sm text-gray-700 ">
                      <button
                        className="text-blue-500 mr-2"
                        onClick={() => handleEditClick(index)}
                      >
                        Edit
                      </button>

                      <button
                        className="text-red-500"
                        onClick={() => confirmDelete(index)} // Show confirmation modal
                      >
                        Delete
                      </button>
                    </td>
                    <td className="py-2 text-sm text-gray-700">
                      <div className="relative inline-block w-12 h-6 align-middle select-none transition duration-200 ease-in">
                        <input
                          type="checkbox"
                          name={`toggle-${index}`}
                          id={`toggle-${index}`}
                          checked={field.visibility} // Set the toggle based on visibility
                          onChange={() => handleToggleVisibility(index)} // Toggle on change
                          className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer transition-transform duration-300 ease-in-out"
                          style={{
                            transform: field.visibility
                              ? "translateX(1.5rem)"
                              : "translateX(0)",
                          }}
                        />
                        <label
                          htmlFor={`toggle-${index}`}
                          className={`toggle-label block overflow-hidden h-6 rounded-full ${
                            field.visibility ? "bg-green-500" : "bg-gray-300"
                          } cursor-pointer transition-colors duration-300`}
                        ></label>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* delete popup  */}
        {showPopup && (
          <div className="fixed inset-0 flex left-20 items-center justify-center shadow bg-gray-200 bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-lg">
              <h2 className="text-md font-semibold mb-6">
                Are you sure you want to delete this field?
              </h2>
              <div className="flex justify-between space-x-4">
                <button
                  className="bg-red-500 text-white py-1 px-6 rounded text-sm"
                  onClick={handleDelete} // Confirm deletion
                >
                  Delete
                </button>
                <button
                  className="bg-gray-500 text-white py-1 px-6 rounded text-sm"
                  onClick={cancelDelete} // Cancel deletion
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {/* add field popup  */}
        <div
          className={`absolute ${
            addField ? "block" : "hidden"
          } top-64 inset-0 flex items-center justify-center`}
        >
          <div class="border-2 border-gray-300 bg-white rounded-md p-4 h-[280px] w-[700px]">
            <IoCloseCircleSharp
              size={24}
              onClick={handleOnclick}
              className="cursor-pointer float-end"
            />
            <h2 className="text-center font-semibold pt-4">Add Field</h2>
            <form action="" onSubmit={handleOnSubmit}>
              <div className="flex flex-wrap justify-around p-2 pt-6 text-sm">
                <div className="w-24 flex flex-col justify-center items-center">
                  <h2 className="mb-2">FieldName</h2>
                  <input
                    type="text"
                    className="border-2 w-48 rounded-md h-8  px-1 border-gray-300"
                    placeholder="Enter name"
                    value={fieldName}
                    onChange={(e) => setFieldName(e.target.value)}
                  />
                </div>
                <div className="w-24 flex flex-col justify-center items-center">
                  <h2 className="mb-2">FieldType</h2>
                  <select
                    name="fieldType"
                    id="fieldType"
                    className="w-48 border-2 h-8 border-gray-300 rounded-md"
                    value={fieldType}
                    onChange={(e) => setFieldType(e.target.value)}
                  >
                    <option value="" disabled>
                      Select{" "}
                    </option>
                    <option value="TEXT">TEXT</option>
                    <option value="NUMBER">NUMBER</option>
                    <option value="ENUM">ENUM</option>
                  </select>
                </div>
              </div>
              <div className="p-4 mt-2 flex">
                <div className="w-[50%] ">
                  {fieldType === "ENUM" ? (
                    <label className="flex items-center space-x-3">
                      <input
                        type="radio"
                        className="form-radio h-3 w-3 text-blue-600"
                        checked={isDropdown}
                        onClick={() => setIsDropdown(!isDropdown)}
                      />
                      <span className="text-gray-700 text-sm">
                        Is this a dropdown?
                      </span>
                    </label>
                  ) : null}

                  {isDropdown && fieldType === "ENUM" && (
                    <div className="mt-4">
                      <input
                        type="text"
                        className="w-72 text-sm px-2 h-8 border-2 border-gray-300 rounded-md "
                        placeholder="Please enter values, comma seprated"
                        onChange={handleInputChange}
                      />
                    </div>
                  )}
                </div>
                <div className="flex justify-end px-8 mt-6 w-[50%]">
                  <button
                    className="flex bg-blue-500 px-8 items-center text-white rounded-md h-8"
                    type="submit"
                  >
                    save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* edit field popup  */}
        {isEditOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
            <div className="bg-white p-4 rounded shadow-lg ">
              <h2 className="text-md font-semibold mb-4">Edit Field</h2>
              <div className="flex gap-4">
                <div className="mb-4 text-sm">
                  <label className="block text-sm text-gray-800 mb-1 font-medium">
                    Field Name
                  </label>
                  <input
                    type="text"
                    value={editField.fieldName}
                    onChange={(e) =>
                      setEditField({ ...editField, fieldName: e.target.value })
                    }
                    className="border border-gray-300 rounded-md w-full p-1 text-sm "
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm text-gray-800 mb-1 font-medium">
                    Field Type
                  </label>
                  <select
                    type="text"
                    className="w-48 border-2 h-8 border-gray-300 rounded-md"
                    value={editField.fieldType}
                    onChange={(e) =>
                      setEditField({ ...editField, fieldType: e.target.value })
                    }
                  >
                    <option value="" disabled>
                      Select{" "}
                    </option>
                    <option value="TEXT">TEXT</option>
                    <option value="NUMBER">NUMBER</option>
                    <option value="ENUM">ENUM</option>
                  </select>
                </div>
              </div>
              {/* for dropdown values  */}
              {editField.fieldType === "ENUM" &&
              editField.possibleValues !== null ? (
                <div className="mb-4">
                  <label className="block text-sm text-gray-800 mb-1 font-medium">
                    Dropdown Values
                  </label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded w-full p-1 text-sm"
                    placeholder="Please enter values, comma seprated"
                    value={editField.possibleValues}
                    onChange={(e) =>
                      setEditField({
                        ...editField,
                        possibleValues: e.target.value
                          .split(",")
                          .map((value) => value.trim()),
                      })
                    }
                  />
                </div>
              ) : null}

              {editField.fieldType === "ENUM" &&
              editField.possibleValues === null ? (
                <div className="mb-4">
                  <label className="flex items-center space-x-3 mb-1">
                    <input
                      type="radio"
                      className="form-radio h-3 w-3 text-blue-600"
                      checked={editDropdown}
                      onClick={() => setEditDropdown(!editDropdown)}
                    />
                    <span className="text-gray-700 text-sm">
                      Is this a dropdown?
                    </span>
                  </label>
                  {editDropdown && (
                    <input
                      type="text"
                      className="border border-gray-300 rounded w-full p-1 text-sm"
                      placeholder="Please enter values, comma seprated"
                      value={editField.possibleValues}
                      onChange={(e) =>
                        setEditField({
                          ...editField,
                          possibleValues: e.target.value
                            .split(",")
                            .map((value) => value.trim()),
                        })
                      }
                    />
                  )}
                </div>
              ) : null}
              <div className="flex justify-end text-sm">
                <button
                  className="bg-blue-500 text-white px-6 py-1 rounded mr-2"
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  className="bg-gray-300 text-gray-700 px-4 py-1 rounded"
                  onClick={() => {
                    setIsEditOpen(false);
                    setEditDropdown(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Settingbar>
  );
};

export default AccountTeams;
