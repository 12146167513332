import React from 'react'

import { BsDatabaseDown } from 'react-icons/bs'

import {
  FaAddressBook,
  FaBell,
 
  FaShieldAlt,
} from "react-icons/fa";
import { HiMiniUsers, HiUsers } from 'react-icons/hi2'
import { MdAccountBalance, MdContactPage, MdOutlineDashboardCustomize, MdSpaceDashboard } from 'react-icons/md'
import { RiUserAddFill, RiUserSettingsLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { FaCodePullRequest } from 'react-icons/fa6'
import { CiBag1 } from 'react-icons/ci'
import { HiDocumentReport } from 'react-icons/hi'
import { PiPlugsConnectedFill } from 'react-icons/pi'
import { IoHelpCircleOutline, IoSettingsOutline } from 'react-icons/io5'

const Settingbar = ({children}) => {
  return (
    <div>

<aside id="cta-button-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0 " aria-label="Sidebar">
  <div className='w-10 absolute h-full bg-gray-500 flex flex-col items-center space-y-4 pt-2'>
    <Link to='/'>
  <MdSpaceDashboard size={20} color="white"/>
  </Link>
  <HiMiniUsers size={20} color="white"/>
  <RiUserAddFill size={20} color="white"/>
  <FaCodePullRequest size={19} color="white"/>
  <CiBag1 size={20} color="white"/>
  <HiDocumentReport size={20} color="white"/>
  <FaBell size={20} color="white"/>
  <PiPlugsConnectedFill size={20} color="white"/>
  <MdContactPage size={20} color="white"/>
  <Link to="/settings" className='h-7 w-7 bg-white flex items-center justify-center rounded-md'>
  <IoSettingsOutline size={20} className='bg-white ' />
  </Link>
  <IoHelpCircleOutline size={20} color="white"/>
  <FaShieldAlt size={20} color="white"/>










  </div>
   <div className="h-full pl-10 py-4 overflow-y-auto  bg-[#f7f9fb]  border-r border-gray-300">
      <ul className="space-y-3 font-medium">
         <li className='flex gap-3 items-center hover:bg-white cursor-pointer h-14 pl-4'>
         <HiUsers color="#2563eb" size={30} />
          <div className='text-sm'>
            <h3 className='font-bold'>Teams</h3>
            <p className='text-xs'>Create Users and Define access levels</p>
          </div>
           
         </li>
         <li className='flex gap-3 items-center h-14 hover:bg-white cursor-pointer pl-4'>
         <MdOutlineDashboardCustomize  color="#2563eb" size={24} />
          <div className='text-sm'>
            <h3 className='font-bold'>Workflows</h3>
            <p className='text-xs'>Setup your Custom Fields</p>
          </div>
           
         </li>
         <li className='flex gap-3 items-center h-14 hover:bg-white cursor-pointer pl-4'>
         <BsDatabaseDown size={24} color="#2563eb" />
          <div className='text-sm'>
            <h3 className='font-bold'>Data Import</h3>
            <p className='text-xs'>Upload your Customers Data from Legacy Tools</p>
          </div>
           
         </li>
         <li className='flex gap-3 items-center h-14 hover:bg-white cursor-pointer pl-4'>
         <MdAccountBalance  color="#2563eb" size={24} />
          <div className='text-sm'>
            <h3 className='font-bold'>Account</h3>
            <p className='text-xs'>Manage your billing and account</p>
          </div>
           
         </li>
         <li>
           
         </li>
         <li>
            
         </li>
        
      </ul>
      
   </div>
</aside>
<div className="p-4 sm:ml-64 bg-gray-50">
{children}
</div>
    </div>
  )
}

export default Settingbar





// import React from 'react';
// import { FaUsers, FaUpload, FaLock, FaMoneyBillAlt } from 'react-icons/fa';
// import { MdAccountBox, MdHealthAndSafety, MdOutlinePlan, MdOutlineExpansion, MdOutlineRequestPage } from 'react-icons/md';
// import Sidebar from '../container/Sidebar';
// import { IoRocketOutline } from 'react-icons/io5';
// import { AiOutlineDollar } from 'react-icons/ai';

// const Settings = () => {
//   const settingsOptions = [
//     { icon: <FaUsers />, title: "Users and Team", description: "Create and manage users, assign them roles" },
//     { icon: <MdAccountBox />, title: "Account Fields", description: "Manage Fields to be shown in the Account Level. Edit, Add or Remove the Fields" },
//     { icon: <FaUpload />, title: "Data Upload", description: "Upload the Account and Health data through a CSV or Sync you API’s" },
//     { icon: <FaLock />, title: "Permissions", description: "Define the Rules of users. Update what they can see and edit." },
//     { icon: <FaMoneyBillAlt />, title: "Plans and Billing", description: "Manage your Triumph Plans and user counts" },
//     { icon: <MdHealthAndSafety />, title: "Customer Health Fields", description: "Manage Fields to be shown in the Health. Edit, Add or Remove the Fields" },
//     { icon: <IoRocketOutline  />, title: "Success Plan Fields", description: "Manage Fields to be shown in Success Plan. Edit, Add or Remove the Fields" },
//     { icon: <AiOutlineDollar />, title: "Expansion Fields", description: "Manage Fields to be shown in Expansion. Edit, Add or Remove the Fields" },
//     { icon: <MdOutlineRequestPage />, title: "Requests / To Do Fields", description: "Manage Fields to be shown in Requests. Edit, Add or Remove the Fields" },
//   ];

//   return (
//     <Sidebar >
//     <div className="p-3 bg-gray-50 border border-gray-200 rounded-md border-opacity-50 shadow">
//       <h2 className="text-2xl font-semibold mb-4">Settings</h2>
//       <div className="bg-white shadow rounded-md cursor-pointer">
//         {settingsOptions.map((option, index) => (
//           <div
//             key={index}
//             className="flex items-center p-4 border-b last:border-none hover:bg-gray-100"
//           >
//             <div className="text-gray-500 text-xl mr-4 ">{option.icon}</div>
//             <div>
//               <h3 className="font-medium text-gray-900 text-sm">{option.title}</h3>
//               <p className="text-gray-500 text-xs">{option.description}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//     </Sidebar>
//   );
// };

// export default Settings;
