import React, { useEffect, useState } from "react";
import { BiEdit, BiSave, BiSearchAlt } from "react-icons/bi";
import { IoSettingsOutline } from "react-icons/io5";
import { LuFilter } from "react-icons/lu";
import Sidebar from "../container/Sidebar";
import { useNavigate } from "react-router-dom";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import { RxOpenInNewWindow } from "react-icons/rx";
import { Link } from "react-router-dom";

const Account = () => {
  const [data, setData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [editRowId, setEditRowId] = useState(null);
  const [editFieldName, setEditFieldName] = useState("");
  const [editValue, setEditValue] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const heading = [
    "Name",
    "organisationCname",
    "Website",
    "Account Stage",
    "Churn Risk",
    "Churn Date",
    "Location",
    "Revenue",
    "Industry",
    "ARR",
    "MRR",
    "CSM",
    "Account Manager",
    "TAM",
    "Support Advisor",
    "Engagement",
    "Engagement Mode",
    "Engagement Freq.",
    "CSM Health",
    "Product Adop. Health",
    "Support Health",
    "Product Name",
    "Product MRR",
    "Start Date",
    "Plan Name",
    "Contract Status",
    "Contract End Date",
  ];

  const initialColumnWidths = Array(heading.length).fill(180);

  const [columnWidths, setColumnWidths] = useState(initialColumnWidths);

  const handleResize = (index, newWidth) => {
    const updatedWidths = [...columnWidths];
    updatedWidths[index] = newWidth;
    setColumnWidths(updatedWidths);
  };
  
  const token = localStorage.getItem("authToken");
  //get all accounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://be.triumphiq.com/accounts", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        result.forEach((entry) => {
          entry.companyInfo = JSON.parse(entry.companyInfo);
          entry.accountInfo = JSON.parse(entry.accountInfo);
          entry.healthAndEngagementInfo = JSON.parse(
            entry.healthAndEngagementInfo
          );
          entry.contractAndProductInfo = JSON.parse(
            entry.contractAndProductInfo
          );
        });

        console.log(result);
        setData(result);
      } catch (err) {
        console.log("error", err);
      }
    };
    fetchData();
  }, []);

  //edit api

  const navigate = useNavigate();

  const handleRowClick = (website) => {
    navigate(`/accounts/${website}`);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data?.filter((entry) => {
    const websiteMatch = entry.website
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const nameField = entry.companyInfo.find(
      (field) => field.fieldName === "Name"
    )?.fieldValue;
    const nameMatch = nameField
      ? nameField.toLowerCase().includes(searchTerm.toLowerCase())
      : false;

    return websiteMatch || nameMatch;
  });

  const handleEditClick = (rowId, section, fieldName, fieldValue) => {
    setEditRowId(rowId);
    setEditFieldName(fieldName);
    setEditValue(fieldValue);
    setIsEditing(true);
  };

  const handleEditSave = async () => {
    const updatedData = data.map((entry) => {
      if (entry.website === editRowId) {
        return {
          ...entry,
          companyInfo: entry.companyInfo.map((field) =>
            field.fieldName === editFieldName
              ? { ...field, fieldValue: editValue }
              : field
          ),
          accountInfo: entry.accountInfo.map((field) =>
            field.fieldName === editFieldName
              ? { ...field, fieldValue: editValue }
              : field
          ),
          healthAndEngagementInfo: entry.healthAndEngagementInfo.map((field) =>
            field.fieldName === editFieldName
              ? { ...field, fieldValue: editValue }
              : field
          ),
          contractAndProductInfo: entry.contractAndProductInfo.map((field) =>
            field.fieldName === editFieldName
              ? { ...field, fieldValue: editValue }
              : field
          ),
        };
      }
      return entry;
    });

    setData(updatedData);
    setEditRowId(null);
    setEditFieldName("");
    setEditValue("");
    setIsEditing(false);

    const updatedEntry = updatedData.find(
      (entry) => entry.website === editRowId
    );

    // Create the payload for the specific updated entry
    const payload = {
      website: updatedEntry.website,
      accountInfo: updatedEntry.accountInfo,
      companyInfo: updatedEntry.companyInfo,
      healthAndEngagementInfo: updatedEntry.healthAndEngagementInfo,
      contractAndProductInfo: updatedEntry.contractAndProductInfo,
    };

    console.log("Payload for update:", payload);
    // Send updated data to the backend
    try {
      const response = await fetch("https://be.triumphiq.com/accounts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
   
      if (!response.ok) {
        throw new Error("Failed to save data");
      }
      const result = await response.json();
      console.log("Save successful:", result);
    } catch (err) {
      console.log("Save error:", err);
    }
  };

  return (
    <Sidebar>
      <div className="h-screen  w-fit">
        <div className="top-10 flex items-center z-10 fixed space-x-8 h-16 bg-white px-3 w-screen">
          <input
            type="text"
            placeholder="Search accounts..."
            onChange={handleSearchChange}
            className="border flex items-center  text-sm py-2 px-2 border-gray-400 rounded-xl w-[600px]"
            style={{ paddingLeft: "32px" }}
          />
          <BiSearchAlt
            size={20}
            style={{ position: "absolute", marginLeft: "5px" }}
          />
          <div className="flex gap-2 items-center">
            <IoSettingsOutline size={20} />
            <LuFilter size={20} />
            {editRowId !== null ? (
              <button
                className="h-8 w-24 bg-blue-500 rounded-md text-white text-xs"
                onClick={handleEditSave}
              >
                Save
              </button>
            ) : null}
          </div>
        </div>
        <div className="w-full mx-auto border-x-1 border-gray-100 mt-28  z-0">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-300 h-16 ">
              <tr>
                {heading?.map((item, index) => (
                  <th
                    key={index}
                    className="text-center font-semibold text-gray-500 tracking-wider space-x-6 h-full"
                    style={{ width: columnWidths[index] }}
                  >
                    <ResizableBox
                      width={columnWidths[index]}
                      height={40}
                      axis="x"
                      resizeHandles={["e"]}
                      minConstraints={[initialColumnWidths[index], 0]}
                      onResize={(e, { size }) =>
                        handleResize(index, size.width)
                      }
                    >
                      <div
                        style={{ padding: "10px", cursor: "ew-resize" }}
                        className="text-sm "
                      >
                        {item}
                      </div>
                    </ResizableBox>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y  divide-gray-200  text-xs  text-center">
              {filteredData?.map((entry, index) => (
                <tr key={index} className="">
                  <td
                    className="md:px-6 whitespace-nowrap  cursor-pointer  h-full w-full relative"
                    onClick={() => handleRowClick(entry?.website)}
                  >
                    {entry?.companyInfo[3]?.fieldValue}
                    <RxOpenInNewWindow className="text-blue-600 absolute top-4 left-28" size={14}  />
                  </td>
                  <td className="md:px-6  whitespace-nowrap ">
                    {entry?.organisationCname}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    <Link
                      to={`/${entry?.companyInfo[4]?.fieldValue}`}
                      target="_blank"
                      className="underline text-blue-600"
                    >
                      {entry?.companyInfo[4]?.fieldValue}
                    </Link>
                  </td>
                  {entry.companyInfo
                  .filter(
                    (field) =>
                      field.fieldName !== "Name" && field.fieldName !== "Website"
                  )
                  .map((field, idx) => (
                    <td
                      key={idx}
                      className="md:px-4 py-4 justify-center items-center relative gap-4"
                    >
                      {editRowId === entry.website && editFieldName === field.fieldName ? (
                        field.fieldType === "ENUM" ? (
                          <select
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                            className="border px-2 py-1 rounded w-28"
                          >
                            <option value={field.fieldValue}>
                              {field.fieldValue}
                            </option>
                          </select>
                        ) : (
                          <>
                            <input
                              type="text"
                              value={editValue}
                              onChange={(e) => setEditValue(e.target.value)}
                              className="border px-2 py-1 rounded w-28"
                            />
                           
                          </>
                        )
                      ) : (
                        <>
                          {field.fieldValue}
                          <BiEdit
                            size={17}
                            className="text-blue-500 hover:text-blue-800 opacity-0 hover:opacity-100 transition-opacity absolute top-4 left-36"
                            onClick={() =>
                              handleEditClick(entry.website, "companyInfo", field.fieldName, field.fieldValue, field.fieldType)
                            }
                          />
                        </>
                      )}
                    </td>
                  ))}
                  {entry.accountInfo.map((field, idx) => (
                    <td
                      key={idx}
                      className="md:px-4 py-4 relative  gap-4"
                    >
                      {editRowId === entry.website &&
                      editFieldName === field.fieldName ? (
                        <>
                          <input
                            type="text"
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                            className="border px-2 py-1 rounded w-28"
                          />
                        </>
                      ) : (
                        <>
                          {field.fieldValue}
                          <BiEdit
                            size={17}
                            className="text-blue-500 hover:text-blue-800 opacity-0 hover:opacity-100 transition-opacity absolute top-4 left-36"
                            onClick={() =>
                              handleEditClick(
                                entry.website,
                                "accountInfo",
                                field.fieldName,
                                field.fieldValue
                              )
                            }
                          />
                        </>
                      )}
                    </td>
                  ))}

{entry.healthAndEngagementInfo.map((field, idx) => (
                  <td key={idx} className="md:px-4 py-4  justify-center relative items-center gap-4">
                    {editRowId === entry.website && editFieldName === field.fieldName ? (
                      field.fieldType === "ENUM" ? (
                        <select
                          value={editValue}
                          onChange={(e) => setEditValue(e.target.value)}
                          className="border px-2 py-1 rounded w-28"
                        >
                          <option value={field.fieldValue}>
                            {field.fieldValue}
                          </option>
                        </select>
                      ) : (
                        <>
                          <input
                            type="text"
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                            className="border px-2 py-1 rounded w-28"
                          />
                        
                        </>
                      )
                    ) : (
                      <>
                        {field.fieldValue}
                        <BiEdit
                          size={17}
                          className="text-blue-500 hover:text-blue-800 opacity-0 hover:opacity-100 absolute top-4 left-36 transition-opacity"
                          onClick={() =>
                            handleEditClick(entry.website, "healthAndEngagementInfo", field.fieldName, field.fieldValue, field.fieldType)
                          }
                        />
                      </>
                    )}
                  </td>
                ))}
                  {entry.contractAndProductInfo.map((field, idx) => (
                    <td
                      key={idx}
                      className="md:px-4 py-4 justify-center relative items-center gap-4"
                    >
                      {editRowId === entry.website &&
                      editFieldName === field.fieldName ? (
                        <>
                          <input
                            type="text"
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                            className="border px-2 py-1 rounded w-28"
                          />
                        </>
                      ) : (
                        <>
                          {field.fieldValue}
                          <BiEdit
                            size={17}
                            className="text-blue-500 hover:text-blue-800 opacity-0 hover:opacity-100 absolute top-4 left-36 transition-opacity"
                            onClick={() =>
                              handleEditClick(
                                entry.website,
                                "contractAndProductInfo",
                                field.fieldName,
                                field.fieldValue
                              )
                            }
                          />
                        </>
                      )}
                    </td>
                  ))}

                  {/* <td className="md:px-6 py-4 flex justify-center items-center gap-4">
                    {editRowId === entry.website &&
                    editFieldName === "Account Stage" ? (
                      <>
                        <input
                          type="text"
                          value={editValue}
                          onChange={(e) => {
                            setEditValue(e.target.value);
                          }}
                          className="border px-2 py-1 rounded w-28"
                        />
                      </>
                    ) : (
                      <>
                        {entry.companyInfo[0]?.fieldValue}
                        <BiEdit
                          size={17}
                          className=" text-blue-500 hover:text-blue-800 opacity-0 hover:opacity-100 transition-opacity"
                          onClick={() =>
                            handleEditClick(
                              entry.website,
                              "Account Stage",
                              entry.companyInfo[0]?.fieldValue
                            )
                          }
                        />
                      </>
                    )}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.companyInfo[2].fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.companyInfo[1].fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.companyInfo[5].fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.companyInfo[6].fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.companyInfo[7].fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.companyInfo[8].fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.companyInfo[9].fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.accountInfo[0].fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.accountInfo[1]?.fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.accountInfo[2].fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.accountInfo[3]?.fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.healthAndEngagementInfo[0]?.fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.healthAndEngagementInfo[1]?.fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.healthAndEngagementInfo[2]?.fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.healthAndEngagementInfo[3]?.fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.healthAndEngagementInfo[4]?.fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.healthAndEngagementInfo[5]?.fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.contractAndProductInfo[0]?.fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.contractAndProductInfo[1]?.fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.contractAndProductInfo[2]?.fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.contractAndProductInfo[3]?.fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.contractAndProductInfo[4]?.fieldValue}
                  </td>
                  <td className="md:px-6 py-4 whitespace-nowrap">
                    {entry.contractAndProductInfo[5]?.fieldValue}
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Sidebar>
  );
};

export default Account;
