import React, { useEffect } from "react";
import Sidebar from "../../container/Sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Onboard = () => {

  const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');
if (token) {
  localStorage.setItem('authToken', token);
}

const navigate = useNavigate();
const location = useLocation();

useEffect(() => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");

  if (token) {
    localStorage.setItem("authToken", token);
  } else {
    navigate("/signup"); // Redirect to signup if token is not present
  }
}, [location, navigate]);

  return (
    <Sidebar>
      <div className="bg-gray-50 p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4 text-center">
          Welcome to TRIUMPH IQ
        </h2>
        <p className="text-gray-700 mb-4 font-semibold">
          Complete your setup with easy steps
        </p>
        <div className="space-y-6">
          <div>
            <h3 className="text-lg font-semibold mb-2">Step 1</h3>
            <Link to="/settings">
            <div className="bg-gray-100 p-4 rounded-lg border-2 border-gray-300 cursor-pointer">
              <p>Add users / CSM / Managers for your accounts</p>
            </div>
            </Link>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-2 ">Step 2</h3>
            <Link to="/settings">
            <div className="bg-gray-100 p-4 rounded-lg border-2 border-gray-300 cursor-pointer">
              <p>Create and Update the Fields for your Accounts</p>
            </div>
            </Link>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-2">Step 3</h3>
            <div className="bg-gray-100 p-4 rounded-lg border-2 border-gray-300 cursor-pointer">
              <p>Upload the Accounts to see the Magic</p>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default Onboard;
